<script>
export default {
  name: 'CreateAccountModal',
  props: {
    modalId: String,
  },
  data() {
    return {
      form: {
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        confirmPassword: '',
      },
      show: true,
    }
  },
  methods: {
    hideModal(showModalId = null) {
      this.$refs['create-account-modal'].hide()
      showModalId && this.$bvModal.show(showModalId)
    },
  },
  computed: {
    currentPath() {
      return window.location.pathname
    },
  },
  mounted() {},
}
</script>

<style scoped lang="scss"></style>
